 @import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inconsolata:wght@200..900&family=Passion+One:wght@400;700;900&display=swap');

 body {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   background-color: #FFDC56;
   font-family: "Dela Gothic One",
     sans-serif;
 }

 .text-passion {
   font-family: "Passion One", sans-serif;
 }

 .text-incon {
   font-family: "Inconsolata", monospace;
 }

 .navshadow {
   box-shadow: 5px 5px 0px 2px rgba(255, 255, 255, 0.20) inset, 5px 5px 0px 0px rgba(0, 0, 0, 0.08);
 }

 .logo {
   text-shadow: 2px 2px 0px #000;
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: #000;
 }

 .heading-shadow {
   text-shadow: 0px 4px 0px #000;
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: #353535;
 }

 .faq-bg {
   border-radius: 7px;
   border-top: 2px solid #000;
   border-right: 2px solid #000;
   border-bottom: 5px solid #000;
   border-left: 2px solid #000;
   background: #F0F0F0;
   box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.08),
     4.25px 4.25px 5px 2.75px rgba(255, 255, 255, 0.50) inset;
 }

 .what-bg {
   background-image: url("./components/assets/img/png/moomoo-bg.png");
   background-position: center;
   background-size: 100% 100%;
   background-repeat: no-repeat;
 }

 .buy-bg {
   background-image: url("./components/assets/img/png/buy-bg.png");
   background-position: center;
   background-size: cover;
 }


 @media (max-width:1023px) {
   .what-bg {
     background-image: none;

   }
 }